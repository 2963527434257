<template>
  <div class="d-flex d-inline-flex align-items-center w-100">
    <b-dropdown
      v-b-tooltip.hover.right="'Download Chat'"
      :id="`dropdown subthread`"
      :no-caret="true"
      v-if="getSubThreads.length !== 0"
      variant="link"
      dropright
    >
      <template #button-content>
        <i class="material-symbols-outlined text-white mt-1">download</i>
      </template>
      <b-dropdown-item-button
        @click="onExport(i)"
        v-for="i in getSubThreads"
        :key="i.subid"
        >{{ i.starttime }}</b-dropdown-item-button
      >
    </b-dropdown>

    <div class="d-flex d-inline-flex align-items-center mx-2 cs-profile w-100">
      <img
        :src="avatar.staff"
        class="m-1"
        style="width: 32px; height: 32px"
        alt="rw"
      />
      <div class="mx-2 agent-profile">
        <div class="font-weight-bold d-flex flex-column">
          <span>{{ getAgent.nama }}</span>
          <small style="color: rgba(255, 255, 255, 0.7)">
            {{ getAgent.dept_name }}
            {{ getAgent.jabatan === false ? "" : " - " + getAgent.jabatan }}
          </small>
        </div>
      </div>
      <small class="ml-auto">{{ version }}</small>
    </div>
  </div>
</template>

<script>
import { version } from "@/common/general.config";
import { image } from "@/common/config";
import { clientCookies } from "@/common/mixins/mix_cookies";
import { request, alert, download } from "@/common/mixins/mix_helper";
import { mapGetters } from "vuex";
import { loader } from "@/common/mixins/mix_loader";

export default {
  name: "AfterLogin",
  mixins: [clientCookies, request, loader, alert, download],
  data() {
    return {
      avatar: image,
      allsubthread: [],
      version: version,
    };
  },
  methods: {
    destroyCookies: function () {
      this.$swal({
        html: "Anda akan logout ?",
        icon: "warning",
        showCancelButton: true,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          // belum menemukan logout / destroy cookies iframe
          this.$store.dispatch("customerLogout");
          location.reload();
        }
      });
    },
    expandProfile: function () {
      this.$swal("Success", "Coming soon, as soon as possible.", "success");
    },

    onExport(params) {
      this.loading(true);
      this.API.post(this.URL.client.export, {
        subid: params.subid,
      })
        .then(({ data }) => {
          let chat = data.data;
          let customer = chat.customer;
          let transcriptChat = `------------------ CUSTOMER ----------------------\n`;
          transcriptChat += `IP    : ${customer.ip}\nNama  : ${customer.nama}\nEmail : ${customer.email}\nPhone : ${customer.phone}\nReferrer : ${customer.referrer}`;
          transcriptChat += `\n\n------------------ TRANSCRIPT CHAT ------------------------------------\n\n`;
          chat.messages.forEach((item) => {
            transcriptChat += `[${item.timestamp}] ${item.nama} : ${item.message}\n`;
          });
          this.download(
            transcriptChat,
            `${params.subid}-${chat.filename}-${customer.email}`
          );
        })
        .catch(() => {
          let id =
            typeof params.subid !== "undefined"
              ? params.subid
              : "Missing subthread id";
          let message = `Sistem kami gagal mengambil data percakapan Anda. <br>Jika Anda membutuhkan arsip percakapan ini, silahkan hubungi kami ke <a href="mailto:info@rumahweb.com">info@rumahweb.com</a> dengan menyertakan kode berikut<br><small><code><b>[ ID - ${id} ]</b></code></small>`;
          this.$swal("Export Failed", message, "error");
        })
        .finally(() => {
          this.loading(false);
        });
    },
  },
  computed: {
    ...mapGetters(["getAgent", "getAllVuexClient", "getSubThreads"]),
  },
};
</script>

<style>
button.btn.dropdown-toggle {
  padding: 0 !important;
}
ul.dropdown-menu {
  max-height: 10em !important;
  overflow: auto;
}
.dropdown-item {
  padding-left: 0.5em !important ;
}
</style>

<style scoped>
.agent-profile {
  margin-top: 6px;
}
@media only screen and (max-width: 576px) {
}
</style>
