<template>
  <div>
    <div class="float-left text-center" id="agentThumb">
      <img
        class="rw-chat-img"
        :src="agentImage"
        :alt="message.name"
        :title="message.name"
      />
      <div class="small text-break">{{ getFirstName(message.name) }}</div>
    </div>
    <div class="rw-chat-text">
      <div
        v-html="nl2br(message.message)"
        class="float-left"
        v-if="
          ['text', 'agent-autoreply'].includes(message.tipe) ||
          (['image', 'file'].includes(message.tipe) &&
            (message.deleted === '1' || message.deleted === true))
        "
      />
      <div
        class="float-left"
        v-if="message.tipe === 'image' && message.deleted === '0'"
      >
        <a :href="mixGetImage(message.message)" target="_blank">
          <img
            :src="mixGetImage(message.message)"
            :alt="message.message"
            style="height: 78px; max-width: 100%"
          />
        </a>
      </div>

      <!--for attachment-->
      <div
        v-if="message.tipe === 'file' && message.deleted === '0'"
        class="d-flex align-items-end"
      >
        <svg class="qc-icon mt-0">
          <use xlink:href="#rw-file-download"></use>
        </svg>
        <a :href="mixGetFiles(message.message)" target="_blank">Download</a>
      </div>
      <div class="float-right">
        <small>{{ mixEpochToHours(message.time) }}</small>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script>
import { waktu, cloudStorage, util } from "@/common/mixins/mix_helper";

export default {
  name: "AgentPart",
  mixins: [waktu, cloudStorage, util],
  props: {
    message: {
      required: true,
    },
    agentImage: {
      required: true,
    },
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * splite name staff
     * --------------------------------------------------------------
     */
    getFirstName: function (fullName) {
      let uname = fullName.split(" ");
      return uname[0];
    },
  },
};
</script>

<style scoped>
/*.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}*/
#agentThumb {
  max-width: 2rem;
}
</style>
