var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.checkTipeMsg(_vm.message.tipe))?_c('BotPartBubble',{attrs:{"message":_vm.message}}):_vm._e(),(
      _vm.message.tipe === 'chooseDepartemen' &&
      _vm.getConfigJs.pilihDepartemen.uuid === parseInt(_vm.message.time)
    )?_c('LeftBubble',{attrs:{"message":_vm.message}},[(_vm.allDeptIsOff)?[_c('div',{staticClass:"my-1 text-center"},[_vm._v(" Mohon maaf, Saat ini staff kami sedang offline."),_c('br'),_vm._v(" Silakan menghubungi kami melalui email atau melalui sistem ticketing pada menu 'Trouble Ticket' di Client Area. Silakan klik tombol berikut untuk menuju Client Area. ")]),_c('a',{staticClass:"btn btn-info btn-block btn-sm rounded-pill",attrs:{"href":"https://new.clientzone.rumahweb.com/support/ticket","target":"_blank"}},[_vm._v("Open Ticket")])]:[_c('p',{staticClass:"mb-2"},[_vm._v("Silahkan pilih departemen :")]),_c('div',{staticClass:"flex gap-1 flex-wrap"},_vm._l((_vm.departmentReady),function(divisi,key){return _c('a',{key:key,staticClass:"btn btn-info rw-bg-3 border-0 btn-sm rounded-pill",class:divisi.status !== 'Online' ? 'disabled' : '',attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.pilihTujuanDivisi(divisi)}}},[_vm._v(_vm._s(divisi.name))])}),0)]],2):_vm._e(),(
      _vm.message.tipe === 'agent-close' &&
      _vm.getConfigJs.rating.uuid === _vm.message.uuid
    )?_c('LeftBubble',{attrs:{"message":_vm.message,"id":_vm.message.time}},[_c('ModuleRating')],1):_vm._e(),(_vm.message.tipe === 'common-question')?_c('LeftBubble',{attrs:{"message":_vm.message}},[_c('p',{staticClass:"mb-2"},[_vm._v("Halo, ada yang bisa kami bantu?")]),_c('CommonQuestion',{attrs:{"uid":_vm.message.uuid,"message":_vm.message.message}})],1):_vm._e(),(_vm.message.tipe === 'form' && _vm.getConfigJs.uuidForm === _vm.message.uuid)?_c('LeftBubble',{attrs:{"message":_vm.message}},[_c('form',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.question),expression:"question"}],staticClass:"form-control",attrs:{"placeholder":"Masukan pertanyaan Anda...","rows":"4"},domProps:{"value":(_vm.question)},on:{"input":function($event){if($event.target.composing)return;_vm.question=$event.target.value}}}),_vm._v(" "),_c('button',{staticClass:"btn btn-primary mt-2 rw-bg-3 border-0",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.sendFormQuestion.apply(null, arguments)}}},[_vm._v(" Submit ")])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }