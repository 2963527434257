<script>
/**
 * --------------------------------------------------
 * snippet untuk lanjut chat / reopen
 * --------------------------------------------------
 */

import { request } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";
import { clientCookies } from "@/common/mixins/mix_cookies";
import { mapGetters } from "vuex";

export default {
  name: "ContinueChat",
  mixins: [loader, request, clientCookies],
  methods: {
    onContinue() {
      this.loading();
      this.API.get(this.URL.client.continue)
        .then(({ data }) => {
          this.cookiesSyncUpdate({
            isContinueChatEnabled: false,
            isMore24Hour: data.is_reset,
            rating: { uuid: null, endtime: null },
          });
        })
        .catch((error) => {
          this.$swal("Error", error.response.data.message, "error");
        })
        .finally(() => {
          this.loading(false);
        });
    },
  },
  computed: {
    ...mapGetters(["getConfigJs"]),
  },
};
</script>

<template>
  <div
    class="flex items-end justify-center"
    v-if="getConfigJs.isContinueChatEnabled && !getConfigJs.rating.uuid"
  >
    <button
      class="btn btn-primary rw-bg-3 border-0 d-flex items-center gap-2"
      type="button"
      @click="onContinue"
    >
      <span class="material-symbols-outlined">comment</span>
      <span> Lanjutkan Chat</span>
    </button>
  </div>
</template>
