<script>
import LeftBubble from "@/components/customer/body/conversation/parts/LeftBubble.vue";
import { clientMessage } from "@/common/mixins/mix_message";
import { clientCookies } from "@/common/mixins/mix_cookies";
import { loader } from "@/common/mixins/mix_loader";
import { actions } from "@/common/mixins/mix_actions";

export default {
  name: "ActionPaymentConfirmationCheck",
  components: { LeftBubble },
  mixins: [actions, clientCookies, clientMessage, loader],
  props: {
    message: {
      required: true,
    },
  },
  data() {
    return {
      id: null,
      is_submitted: false,
    };
  },
  methods: {
    async onCheck() {
      try {
        if (this.is_deleted) {
          throw "Forbidden";
        }

        if (!this.id) {
          throw "Masukan ID Konfirmasi terlebih dahulu!";
        }

        this.is_submitted = true;
        await this.submit_action_command(
          `ID Konfirmasi ${this.id}`,
          "payment_confirmation_check_message",
          {
            action_message: this.id,
          }
        );
      } catch (e) {
        this.$swal("", e, "error");
      }
    },

    async onCancel() {
      if (this.is_deleted) {
        return false;
      }

      this.loading();
      await this.cancel_action(
        this.message.uuid,
        "Saya tidak jadi mengecek status konfirmasi pembayaran"
      );
      this.loading(false);
    },
  },
  computed: {
    is_deleted() {
      return [1, "1", true].includes(this.message.deleted);
    },
  },
};
</script>

<template>
  <LeftBubble :message="message" :showTime="is_deleted">
    <template v-if="is_deleted">
      <p class="mb-0 flex items-center gap-1">
        <span class="material-symbols-outlined">description</span> Form Cek
        Konfirmasi Pembayaran
      </p>
    </template>
    <template v-else>
      <p>Masukan ID konfirmasi pembayaran Anda :</p>
      <form @submit.stop.prevent="onCheck">
        <div class="form-group">
          <input
            type="text"
            v-model="id"
            class="form-control"
            placeholder="Contoh RWB-1234"
            :disabled="is_deleted"
          />
        </div>
        <div class="flex gap-2">
          <button
            class="btn btn-primary rw-bg-3 border-0"
            type="submit"
            :disabled="is_deleted || is_submitted"
          >
            Check
          </button>
          <button
            class="btn btn-secondary border-0"
            type="button"
            @click="onCancel"
            :disabled="is_deleted || is_submitted"
          >
            Batal
          </button>
        </div>
      </form>
    </template>
  </LeftBubble>
</template>

<style scoped></style>
