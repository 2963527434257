<script>
import LeftBubble from "@/components/customer/body/conversation/parts/LeftBubble.vue";
import { clientMessage } from "@/common/mixins/mix_message";
import { clientCookies } from "@/common/mixins/mix_cookies";
import { request, alert, util, waktu } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";
import { actions } from "@/common/mixins/mix_actions";

export default {
  name: "ActionPaymentConfirmation",
  props: {
    message: {
      required: true,
    },
  },
  mixins: [
    actions,
    alert,
    clientCookies,
    clientMessage,
    loader,
    request,
    waktu,
    util,
  ],
  components: { LeftBubble },
  data() {
    return {
      banks: [],
      form: {
        invoices: [{ id: null, amount: 0, unique: Math.random() }],
        date: this.mixToday(),
        transaction_type: "order", // order,upgrade,deposit,settlement,other
        transaction_note: "",
        bank: "",
        amount: 0,
        sender: "",
        file: null,
        differentAmount: false,
        diff_reason: "",
      },
      is_submitted: false,
    };
  },
  methods: {
    async onConfirm() {
      this.is_submitted = true;
      if (this.is_deleted) {
        return false;
      }

      try {
        const {
          invoices,
          bank,
          sender,
          amount,
          date,
          transaction_type,
          transaction_note,
        } = this.form;
        if (!bank || !sender || !amount || !date || !transaction_type) {
          throw "Form belum lengkap";
        }

        let params = this.form;
        let message = `Konfirmasi tagihan ${transaction_type} `;
        const bankname = this.banks.find((b) => b.id === bank);

        if (this.is_need_invoiceid) {
          params.transaction_note = "";
          const invoiceArr = invoices.map((item) => `#${item.id}`);
          const invoiceStr = invoiceArr.join(", ");
          message += `invoice ${invoiceStr}`;
        } else {
          params.invoices = "";
          message += `keterangan '${transaction_note}' `;
        }

        message += ` ke bank ${bankname.text} sebesar ${this.toRupiah(
          amount
        )} pada tanggal ${date} dengan an. pengirim ${sender}`;
        await this.submit_action_command(
          message,
          "payment_confirmation_message",
          {
            file: params.file,
            extra: params,
            isFile: true,
          }
        );
      } catch (e) {
        this.$swal("ERROR", e, "error");
      }
    },

    getBank() {
      this.loading();
      this.API.get(this.URL.actions.payment_banks)
        .then(({ data }) => {
          this.banks = data;
        })
        .catch(() => {
          this.banks = [];
        })
        .finally(() => {
          this.loading(false);
        });
    },

    onCheckInvoice(invoceiId, index) {
      if (this.is_deleted) {
        return false;
      }

      const invoiceIsExist = this.form.invoices.filter(
        (item) => item.id === invoceiId
      );
      if (invoiceIsExist.length > 1) {
        this.$swal("", `Invoice ID #${invoceiId} sudah ada`, "warning");
        this.removeInvoice(index);
        return false;
      }

      this.loading();
      this.API.post(this.URL.actions.payment_checkinvoice, {
        invoiceid: invoceiId,
      })
        .then(({ data }) => {
          this.form.invoices[index].amount = this.toRupiah(
            this.toInteger(data.data.amount),
            false
          );
          this.calculateTotal();
        })
        .catch((err) => {
          this.form.invoices[index].id = null;
          this.form.invoices[index].amount = 0;
          this.mixToast(err.response.data.message, {
            style: {
              background: "var(--danger)",
            },
            position: "center",
          });
        })
        .finally(() => {
          this.loading(false);
        });
    },

    addInvoice() {
      if (!this.is_deleted) {
        let is_empty = this.form.invoices.find(
          (e) => e.id === "" || e.id == null
        );
        if (typeof is_empty === "undefined") {
          this.form.invoices.push({ id: "", amount: 0, unique: Math.random() });
        } else {
          this.mixToast("Lengkapi dahulu No. Invoice yang masih kosong", {
            style: {
              background: "var(--info)",
            },
          });
        }
      }
    },

    removeInvoice(index) {
      this.form.invoices.splice(index, 1);
      this.calculateTotal();
    },

    calculateTotal() {
      let sumAmount = this.form.invoices[0].amount;
      if (this.form.invoices.length > 1) {
        sumAmount = this.form.invoices.reduce(
          (a, b) => this.toInteger(a.amount) + this.toInteger(b.amount)
        );
      }
      this.form.amount = this.toRupiah(this.toInteger(sumAmount), false);
    },

    chooseFile(e) {
      if (this.is_deleted) {
        return false;
      }

      const allowed_extensions = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/bmp",
      ];
      const file = e.target.files[0];
      try {
        if (!allowed_extensions.includes(file.type)) {
          throw `File <b>${file.type}</b> tidak diizinkan, silahkan gunakan file <b>png, jpg, jpeg, atau webp</b> `;
        }

        if (Math.round(file.size / 1024) > 10240) {
          throw `Maksimal ukuran file 10MB`;
        }

        this.form.file = file;
      } catch (err) {
        e.target.value = "";
        this.$swal("ERRROR", err, "error");
      }
    },

    async onCancel() {
      this.is_submitted = true;
      this.loading();
      await this.cancel_action(this.message.uuid, "Saya tidak jadi konfirmasi");
      this.loading(false);
    },

    onChangeAmount() {
      this.form.amount = this.toRupiah(this.toInteger(this.form.amount), false);
    },

    onClickIsDifferentAmount() {
      this.form.differentAmount = !this.form.differentAmount;
      if (!this.form.differentAmount) {
        this.calculateTotal();
      }
    },
  },
  computed: {
    is_deleted() {
      return [1, "1", true].includes(this.message.deleted);
    },
    is_need_invoiceid() {
      return !["deposit", "other"].includes(this.form.transaction_type);
    },
  },
  mounted() {
    if (!this.is_deleted) {
      this.getBank();
    }
  },
};
</script>

<template>
  <LeftBubble :message="message" :showTime="is_deleted">
    <template v-if="is_deleted">
      <p class="mb-0 flex items-center gap-1">
        <span class="material-symbols-outlined">description</span> Form
        Konfirmasi Pembayaran
      </p>
    </template>
    <template v-else>
      <p class="mb-2">
        Silakan lengkapi form berikut untuk melakukan konfirmasi pembayaran :
      </p>
      <form @submit.stop.prevent="onConfirm">
        <div class="form-group">
          <label for="" class="mb-1 font-bold">Jenis Tagihan</label>
          <select
            class="form-control form-control-sm"
            v-model="form.transaction_type"
          >
            <option value="order">Order Baru/ Perpanjangan</option>
            <option value="upgrade">Upgrade/ Downgrade</option>
            <option value="deposit">Deposit</option>
            <option value="settlement">Pelunasan</option>
            <option value="other">Lain-lain</option>
          </select>
        </div>
        <div class="form-group" v-if="is_need_invoiceid">
          <label class="mb-0 font-bold">Nomor Invoice</label>
          <div
            class="flex items-center gap-2"
            v-for="(i, index) in form.invoices"
            :key="i.unique"
            style="margin-bottom: 0.2rem"
          >
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Nomor Invoice"
              v-model="i.id"
              @blur="onCheckInvoice(i.id, index)"
              :disabled="is_deleted"
              required
            />
            <button
              class="btn rw-bg-3 text-white btn-sm"
              v-if="form.invoices.length - 1 === index"
              type="button"
              :disabled="is_deleted"
              style="height: 1.5rem"
            >
              <i
                class="material-symbols-outlined text-sm"
                style="line-height: 1"
                @click.stop.prevent="addInvoice()"
                >add</i
              >
            </button>
            <button
              class="btn btn-danger btn-sm"
              v-else
              type="button"
              :disabled="is_deleted"
              style="height: 1.5rem"
            >
              <i
                class="material-symbols-outlined text-sm"
                style="line-height: 1"
                @click="removeInvoice(index)"
                >close</i
              >
            </button>
          </div>
        </div>
        <div class="form-group" v-else>
          <label for="" class="mb-1 font-bold">Keterangan Transaksi</label>
          <textarea
            class="form-control form-control-sm"
            v-model="form.transaction_note"
            placeholder="Isi dengan keterangan transaksi yang akan dikonfirmasi."
          />
        </div>
        <div class="form-group">
          <label for="" class="mb-1 font-bold">Tanggal Transfer</label>
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="form.date"
            :disabled="is_deleted"
            required
          />
        </div>
        <div class="form-group">
          <label for="" class="mb-1 font-bold">Bank Tujuan</label>
          <select
            class="form-control form-control-sm"
            v-model="form.bank"
            :disabled="is_deleted"
            required
          >
            <option v-for="i in banks" :value="i.id" :key="i.id">
              {{ i.text }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="" class="mb-1 font-bold">Jumlah Dana</label>
          <input
            type="text"
            @keyup="onChangeAmount"
            class="form-control form-control-sm"
            v-model="form.amount"
            :disabled="
              (!form.differentAmount || is_deleted) && is_need_invoiceid
            "
            required
            min="1"
          />
          <div class="small mt-2" v-if="is_need_invoiceid">
            <input
              type="checkbox"
              :checked="form.differentAmount"
              @click="onClickIsDifferentAmount"
            />
            Pilih opsi ini jika jumlah dana yang dikirim berbeda dengan jumlah
            tagihan invoice
          </div>
        </div>
        <div
          class="form-group"
          v-if="form.differentAmount && is_need_invoiceid"
        >
          <label for="" class="mb-1 font-bold">Alasan Dana Berbeda</label>
          <textarea
            class="form-control form-control-sm"
            v-model="form.diff_reason"
            placeholder="Isi dengan alasan dana yang dikirim berbeda dengan tagihan invoice"
          />
        </div>
        <div class="form-group">
          <label for="" class="mb-1 font-bold">Nama Pengirim</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="form.sender"
            placeholder="Nama pengirim"
            :disabled="is_deleted"
            required
          />
        </div>
        <div class="form-group">
          <label for="" class="mb-1 font-bold">Bukti Pembayaran</label>
          <input
            type="file"
            class="form-control form-control-sm"
            @change="chooseFile"
            :disabled="is_deleted"
          />
        </div>
        <div class="flex gap-2 mt-2">
          <b-button
            variant="primary"
            style="height: 1.4rem; font-size: 0.7rem"
            size="sm"
            class="font-weight-bold rw-bg-3 border-0"
            type="submit"
            :disabled="is_deleted || is_submitted"
            >Submit</b-button
          >
          <b-button
            variant="secondary"
            style="height: 1.4rem; font-size: 0.7rem"
            size="sm"
            class="font-weight-bold border-0"
            type="button"
            @click="onCancel"
            :disabled="is_deleted || is_submitted"
            >Batal</b-button
          >
        </div>
      </form>
    </template>
  </LeftBubble>
</template>

<style scoped lang="scss">
.form-group {
  margin-bottom: 0.1rem !important;

  label {
    font-size: 80% !important;
    margin-bottom: 0.1rem !important;
  }
}
input.form-control-sm,
select.form-control-sm {
  height: 1.5rem !important;
  font-size: 0.7rem !important;
}
</style>
