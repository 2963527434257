<script>
import LeftBubble from "@/components/customer/body/conversation/parts/LeftBubble.vue";
import { loader } from "@/common/mixins/mix_loader";
import { actions } from "@/common/mixins/mix_actions";

export default {
  name: "ActionPinVerification",
  mixins: [actions, loader],
  components: { LeftBubble },
  props: {
    message: {
      required: true,
    },
  },
  data() {
    return {
      is_submitted: false,
      pin: {
        1: "",
        2: "",
        3: "",
        4: "",
      },
    };
  },
  methods: {
    async onCheck() {
      try {
        if (this.is_deleted) {
          throw "Forbidden";
        }

        const pin = Object.values(this.pin).join("");
        if (!this.is_pinComplete) {
          throw "PIN belum lengkap";
        }

        this.is_submitted = true;
        await this.submit_action_command(
          `PIN clientzone saya ${pin}`,
          "pin_verification_message",
          {
            action_message: pin,
          }
        );
      } catch (e) {
        this.$swal("", e, "error");
      }
    },

    async onCancel() {
      if (this.is_deleted) {
        return false;
      }

      this.is_submitted = true;
      this.loading();
      await this.cancel_action(
        this.message.uuid,
        "Saya tidak jadi melakukan verifikasi PIN"
      );
      this.loading(false);
    },

    onPaste(e) {
      const text = e.clipboardData.getData("text");
      const split = text.split("");
      for (let i = 1; i <= 4; i++) {
        this.pin[i] = split[i - 1];
      }
    },

    toNextField(e, index) {
      if (e.key.match(/[0-9]/)) {
        this.pin[index] = e.key;
        setTimeout(() => {
          this.$refs.input[index].focus();
        }, 200);
      } else {
        if (e.key !== "v" && e.key !== "Control") {
          this.pin[index] = "";
        }
      }
    },
  },
  computed: {
    is_deleted() {
      return [1, "1", true].includes(this.message.deleted);
    },
    is_pinComplete() {
      const pin = Object.values(this.pin).join("");
      return pin.length === 4;
    },
  },
};
</script>

<template>
  <LeftBubble :message="message">
    <template v-if="is_deleted">
      <p class="mb-0 flex items-center gap-1">
        <span class="material-symbols-outlined">description</span> Form
        verifikasi PIN ClientZone
      </p>
    </template>
    <template v-else>
      <p class="mb-3 text-center">Masukan PIN ClientZone</p>
      <form @submit.stop.prevent="onCheck">
        <div class="form-group flex justify-content-center gap-2">
          <input
            type="text"
            @paste="onPaste($event)"
            ref="input"
            @keyup.stop.prevent="toNextField($event, i)"
            minlength="1"
            maxlength="1"
            v-model="pin[i]"
            class="text-center font-weight-bold border-secondary form-control"
            :disabled="is_deleted"
            v-for="i in 4"
            :key="i"
            style="width: 15%"
          />
        </div>
        <div class="flex gap-3 justify-center">
          <button
            class="btn btn-primary rw-bg-3 border-0"
            type="submit"
            :disabled="is_deleted || is_submitted || !is_pinComplete"
          >
            Verifikasi
          </button>
          <button
            class="btn btn-secondary border-0"
            type="button"
            @click="onCancel"
            :disabled="is_deleted || is_submitted"
          >
            Batal
          </button>
        </div>
      </form>
    </template>
  </LeftBubble>
</template>

<style scoped></style>
