<template>
  <div>
    <BotPartBubble v-if="checkTipeMsg(message.tipe)" :message="message" />

    <!--pilih divisi-->
    <LeftBubble
      :message="message"
      v-if="
        message.tipe === 'chooseDepartemen' &&
        getConfigJs.pilihDepartemen.uuid === parseInt(message.time)
      "
    >
      <template v-if="allDeptIsOff">
        <div class="my-1 text-center">
          Mohon maaf, Saat ini staff kami sedang offline.<br />
          Silakan menghubungi kami melalui email atau melalui sistem ticketing
          pada menu 'Trouble Ticket' di Client Area. Silakan klik tombol berikut
          untuk menuju Client Area.
        </div>
        <a
          href="https://new.clientzone.rumahweb.com/support/ticket"
          target="_blank"
          class="btn btn-info btn-block btn-sm rounded-pill"
          >Open Ticket</a
        >
      </template>
      <template v-else>
        <p class="mb-2">Silahkan pilih departemen :</p>
        <div class="flex gap-1 flex-wrap">
          <a
            v-for="(divisi, key) in departmentReady"
            :key="key"
            :class="divisi.status !== 'Online' ? 'disabled' : ''"
            class="btn btn-info rw-bg-3 border-0 btn-sm rounded-pill"
            href="javascript:void(0)"
            @click="pilihTujuanDivisi(divisi)"
            >{{ divisi.name }}</a
          >
        </div>
      </template>
    </LeftBubble>

    <!--rating-->
    <LeftBubble
      :message="message"
      :id="message.time"
      v-if="
        message.tipe === 'agent-close' &&
        getConfigJs.rating.uuid === message.uuid
      "
    >
      <ModuleRating />
    </LeftBubble>

    <!--common question-->
    <LeftBubble :message="message" v-if="message.tipe === 'common-question'">
      <p class="mb-2">Halo, ada yang bisa kami bantu?</p>
      <CommonQuestion :uid="message.uuid" :message="message.message" />
    </LeftBubble>

    <!--form textarea-->
    <LeftBubble
      :message="message"
      v-if="message.tipe === 'form' && getConfigJs.uuidForm === message.uuid"
    >
      <form>
        <textarea
          v-model="question"
          placeholder="Masukan pertanyaan Anda..."
          rows="4"
          class="form-control"
        />
        <button
          @click.stop.prevent="sendFormQuestion"
          type="button"
          class="btn btn-primary mt-2 rw-bg-3 border-0"
        >
          Submit
        </button>
      </form>
    </LeftBubble>
  </div>
</template>

<script>
import "@/assets/css/customer/rw-rating.scss";
import { waktu, request, alert } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";
import { clientCookies } from "@/common/mixins/mix_cookies";
import { mapGetters } from "vuex";
import { clientMessage } from "@/common/mixins/mix_message";
import AppConfig from "@/common/config/app.config.json";
import BotPartBubble from "@/components/customer/body/conversation/BotPartBubble.vue";
import CommonQuestion from "@/components/customer/modules/CommonQuestion.vue";
import ModuleRating from "@/components/customer/modules/ModuleRating.vue";
import LeftBubble from "@/components/customer/body/conversation/parts/LeftBubble.vue";

export default {
  name: "BotPart",
  mixins: [waktu, clientMessage, clientCookies, loader, request, alert],
  components: { LeftBubble, CommonQuestion, BotPartBubble, ModuleRating },
  props: {
    message: {
      required: true,
    },
    departmentReady: {
      required: true,
    },
  },
  data() {
    return {
      botImage: "img/rw/bot.png",
      question: "",
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * memilih departement untuk melanjutkan staff
     * nama akan dikirim / $emit ke parent untuk diproses
     * jika terjadi kegagalan ketika request oepenthread maka
     * cache akan dihapus dibersihkan dan mulai baru
     * --------------------------------------------------------------
     */
    pilihTujuanDivisi: function (divisi) {
      if (divisi.status !== "Online") {
        this.$swal(
          "",
          `Maaf, departemen ${divisi.name} sedang ${divisi.status}`,
          "warning"
        );
        return;
      }

      this.loading();
      let cookiesCurrent = this.cookiesGet();
      if (cookiesCurrent) {
        /**
         * --------------------------------------------------
         * supaya ketika proses request dan diinterup (ex refresh) maka
         * tombol pilih divisi tetep hilang tidak akan tampil lagi.
         * asumsi jika error openThread maka register dr awal jika tidak maka tombol pilih
         * divisi akan muncul lagi ketika client kirim pesan
         * --------------------------------------------------
         */
        this.cookiesSyncUpdate({ pilihDepartemen: { uuid: null } });

        let alertChat = () => {
          this.$swal({
            title: "Perhatian",
            html: "Anda sudah memiliki percakapan Aktif. Klik tombol dibawah untuk melanjutkan chat Anda",
            icon: "info",
            allowOutsideClick: false,
            allowEnterKey: false,
            confirmButtonText: "Lanjutkan",
          });
        };

        /**
         * --------------------------------------------------
         * cannot send reopen when null
         * --------------------------------------------------
         */
        if (cookiesCurrent.pilihDepartemen.uuid === null) {
          this.$store.dispatch("sendLogAPI", {
            actor: "client",
            message: "Cookies dept is null ketika reopen thread",
            cookies: cookiesCurrent,
            sendTelegram: true,
          });
          // this.trashAdd("pilihDept", "cookies null", cookiesCurrent);
          alertChat();
          this.loading(false);
          return;
        }

        const isMore24Hour = false; // this.cookiesGet("isMore24Hour");
        this.API.post(this.URL.thread.openThread, {
          deptid: parseInt(divisi.id),
          takeover: isMore24Hour ? 0 : 1,
        })
          .then(({ data }) => {
            if (typeof data.code !== "undefined") {
              // handle jika ada kemungkinan muncul button divisi > 1
              if (data.code === 201) {
                alertChat();
              }

              if (data.code === 200) {
                let configParam = {
                  subid: data.data.subid,
                  isContinueChatEnabled: false,
                };

                if (isMore24Hour) {
                  configParam = {
                    ...configParam,
                    isTakeOver: false,
                  };
                } else {
                  configParam = {
                    ...configParam,
                    isTakeOver: true,
                  };
                }
                this.cookiesSyncUpdate(configParam);
              }
            }
          })
          .catch(() => {
            // this.trashAdd("pilihDept", "pilih departemen gagal");
            this.$swal({
              title: "Error",
              html: `Terjadi kegagalan dalam mengambil percakapan Anda yang terdahulu.<br>Sistem akan membuat thread baru.<br>Klik tombol <b>"Mulai"</b> untuk memulai thread baru.`,
              icon: "error",
              allowOutsideClick: false,
              allowEnterKey: false,
              confirmButtonText: "Mulai",
            }).then(({ isConfirmed }) => {
              if (isConfirmed) {
                this.handleLogoutFirebase();
              }
            });
          })
          .finally(() => {
            this.loading(false);
          });
      } else {
        this.handleLogoutFirebase();
      }
    },

    /**
     * --------------------------------------------------------------
     * check tipe message yang ditampilkan sebagai alert
     * --------------------------------------------------------------
     */
    checkTipeMsg: function (tipe) {
      let tipeAsAlert = [
        "alert",
        "agent-connect",
        "agent-join",
        "agent-leave",
        //"agent-close",
        "agent-off",
      ];
      return tipeAsAlert.includes(tipe);
    },

    /**
     * --------------------------------------------------------------
     * clear cookies & localstorage & logout firebase & reload
     * --------------------------------------------------------------
     */
    handleLogoutFirebase() {
      this.$store
        .dispatch("FbAuthSignOut")
        .then(() => {
          this.cookiesRemove(); // make sure remove cookies
          localStorage.removeItem(AppConfig.rwStorage.customer.token);
          localStorage.removeItem(AppConfig.rwStorage.agent.config);
        })
        .finally(() => {
          location.reload();
        });
    },

    async sendFormQuestion() {
      if (this.question !== "") {
        this.loading();
        await this.clientSendMessage(this.question);
        await this.cookiesSyncUpdate({ uuidForm: null });
        this.loading(false);
      }
    },
  },
  computed: {
    allDeptIsOff: function () {
      let online = this.departmentReady.filter(
        (dept) => dept.status.toLowerCase() === "online"
      );
      return online.length === 0;
    },
    ...mapGetters(["getConfigJs"]),
  },
};
</script>

<style scoped lang="scss">
.alert-message {
  font-size: 0.8rem;
  margin-top: 0.9%;
  margin-bottom: 0.2%;
}

textarea {
  &::placeholder {
    font-size: 0.9em;
  }
  &:focus {
    box-shadow: none;
  }
}
</style>
